import React, { useState } from "react";
import { FormRendererParent, ViewForm } from "form-configurator";

import {
  Button,
  Typography,
  makeStyles,
  TextField,
  Box,
  AppBar,
  Toolbar,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   textAlign: "center",
  //   // marginTop: "10%",
  // },

  text: {
    "& > *": {
      margin: theme.spacing(1),
      width: "60ch",
    },
  },
  form: {
    marginTop: "6%",
  },
}));

function Home() {
  const classes = useStyles();
  const [id, setId] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [idvalid, setIdvalid] = useState(false);
  const [data, setdata] = React.useState({});
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let idvalue = e.target.value;
    setId(idvalue);
    let result = regexExp.test(idvalue);
    if (result) {
      setIdvalid(true);
    } else {
      setIdvalid(false);
      setShowForm(false);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(id);
    setShowForm(true);
    setShow(true);
  };
  const handleCancel =() => {
    alert("Cancelled...")
  }

  return (
    <>
      <AppBar position="absolute" color="transparent">
        <Toolbar>
          <Typography variant="h6" style={{ color: "#0B8DFB" }}>
            Form Configurator
          </Typography>
          <div style={{ paddingLeft: 10 }}>v0.1.27</div>
        </Toolbar>
      </AppBar>
      <div hidden={show}>
        <div className={classes.root}>
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              m={10}
            >
              <Box className={classes.text}>
                <TextField
                  id="form id"
                  label="Form id"
                  variant="outlined"
                  value={id}
                  onChange={handleChange}
                  size="small"
                />
              </Box>
              <Box marginLeft={2} marginTop={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={!idvalid}
                  style={{ height: "40px" }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </div>
      </div>

      <>
        {showForm && (
          <>
            <div className={classes.form}>
              <FormRendererParent
                onSaveForm={(data) => {
                  // console.log(data, "data")
                  setdata(data);
                }}
                id={id}
                answer={data.answer}
                btnName="save"
                submitBtnBottom= "Right"
                negativeBtn="Cancel"
                cancelPresent={false}
                headerPresent={true}
                formTitle={true}
                handleCancelBtn={()=>handleCancel()}
                alertRequired
                divider
              />

              <ViewForm answer={data.answer} formName={data.name} form={data.form} />
            </div>
          </>
        )}
      </>
    </>
  );
}


export default Home;
